<template>
  <div
    v-if="marqueeData"
    class="mb-4 background"
  >
    <MarqueeText :duration="marqueeData.length * 4">
      <div class="d-flex">
        <div
          v-for="(user, index) in marqueeData"
          :key="user.account + index"
          class="user my-2 mx-8 d-flex align-center"
        >
          <div class="img">
            <v-avatar :style="{ border: `2px solid ${primaryClr}` }">
              <img
                :src="baseImgUrl + user.game_image"
                class="h-100 w-100 object-fit"
                :alt="user.game_name"
              >
            </v-avatar>
          </div>

          <div class="content ml-3 white--text caption">
            <div class="name">
              {{ user.account }}
              <span class="ml-2 primary--text font-weight-bold">{{ user.game_name }}</span>
            </div>
            <div class="amount font-weight-bold">
              <span class="primary--text">{{ $t('game.award') }}</span>
              <span class="subtitle-2">
                {{ $t(`global.${currencySymbol}`) + user.win_amount }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </MarqueeText>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarqueeText from 'vue-marquee-text-component'

export default {
  components: { MarqueeText },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currencySymbol', 'theme']),

    baseImgUrl() {
      return this.data.data.image_url
    },

    marqueeData() {
      const cloneData = JSON.parse(JSON.stringify(this.data.data.marquee))
      return cloneData
    },

    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  background: center / cover no-repeat url('~@/assets/image/marquee_bg.png'), #222;
}
.object-fit {
  object-fit: cover;
}
</style>
